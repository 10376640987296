import React, { useEffect } from 'react'
import Loader from '../components/misc/Loader'
import SpotLoader from '../components/misc/SpotLoader'
import useAuth from '../hooks/useAuth'
import { useRouter } from 'next/router'
export default function index() {
  const { user, loading } = useAuth()
  const router = useRouter()
  useEffect(() => {
    console.log(user, loading)
    if (loading) return
    if (user && !user.isAnonymous) {
      router.push('/packs')
    } else {
      router.push('/landing')
    }
  }, [user, loading])
  return (
    <Loader />
  )
}
